<template>
  <div
    class="verification-kyc-modal"
    :class="{ mobile: isMobile }"
    data-t="verification-kyc-modal"
  >
    <div v-if="isMobile" class="close-wrapper">
      <div class="close-icon">
        <StIcon name="cross-large" @click="handleClose" />
      </div>
    </div>
    <div class="content">
      <div class="header-section">
        <StIcon
          :name="isForbidden ? 'circle-cross' : 'id-card-clip-solid'"
          :size="isMobile ? 48 : 64"
          class="header-icon"
        />
        <div class="text-section">
          <h2 class="title-text">
            {{ title }}
          </h2>
          <p class="main-text">{{ mainText }}</p>
        </div>
      </div>
      <div
        v-if="deposit && !isForbidden"
        class="deposit"
        data-t="verification-kyc-deposit"
      >
        <div class="deposit-loader">
          <StSpinner :size="20" />
        </div>
        <div>
          <div class="deposit-header">
            <span class="deposit-title">{{
              t('payments.kycVerification.deposit')
            }}</span>
            <span class="deposit-badge">
              {{ t('payments.kycVerification.awaitingKyc') }}
            </span>
          </div>
          <div class="deposit-date">{{ createdDate }}</div>
        </div>
        <div class="deposit-account">
          <div class="account-currency">
            <span>+{{ formatCrypto(deposit?.amount) }}</span>
            <StIcon :name="currencyIcon" size="16" />
          </div>
          <span class="account-fiat">~{{ formatFiat(fiatAmount || '') }}</span>
        </div>
      </div>
      <div class="button-wrapper">
        <StButton
          :label="labelButton"
          :type="isForbidden ? 'gray' : 'primary'"
          class="submit-button"
          :size="isMobile ? 'l' : 'xl'"
          to="/personal/kyc"
          block
          data-t="verification-kyc-submit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVerificationStore } from '../../stores/useVerificationStore'
import { useVerificationDeps } from '../../useDeps'

const { t } = useI18n()
const { isMobile } = usePlatform()
const route = useRoute()
const router = useRouter()
const { parseDate } = useDate()

function handleClose() {
  router.replace({ query: { ...route.query, modal: undefined } })
}

const verificationStore = useVerificationStore()
const { userVerification } = storeToRefs(verificationStore)
const { getCurrencyIcon, getFiatAmount, appCurrencyCode } =
  useVerificationDeps()
const { format: formatCrypto } = useCryptoFormatter()
const { format: formatFiat } = useCurrencyFormatter({
  currency: appCurrencyCode.value,
})

const deposit = computed(() => userVerification.value?.deposit)
const createdDate = computed(() =>
  parseDate(deposit.value?.createdAt).format('DD.MM.YY, hh:mm'),
)

const currencyIcon = computed(
  () => deposit.value && getCurrencyIcon(deposit.value.currencyId),
)
const fiatAmount = computed(
  () =>
    deposit.value &&
    getFiatAmount(deposit.value.currencyId, deposit.value.amount),
)

const isForbidden = computed(
  () => userVerification.value && userVerification.value.status === 'rejected',
)

const mainText = computed(() =>
  isForbidden.value
    ? t('payments.kycVerification.textProhibited')
    : t('payments.kycVerification.textAwaiting'),
)

const title = computed(() =>
  isForbidden.value
    ? t('payments.kycVerification.titleProhibited')
    : t('payments.kycVerification.titleSuccess'),
)

const labelButton = computed(() =>
  isForbidden.value
    ? t('payments.kycVerification.subtitleForbidden')
    : t('payments.kycVerification.buttonAwaiting'),
)
</script>

<style scoped>
.verification-kyc-modal {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
  align-items: center;
  justify-content: center;

  width: 480px;

  background: radial-gradient(
      50% 50% at 50% 0%,
      rgb(255 255 255 / 12%) 0%,
      rgb(17 17 25 / 12%) 100%
    ),
    var(--background-base, #111118);
  border-radius: var(--border-radius-200);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  justify-content: center;

  padding: var(--spacing-500) var(--spacing-600);
}

.header-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  align-items: center;
}

.text-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;

  text-align: center;
}

.title-text {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-xl-semibold);
}

.main-text {
  margin: 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.deposit {
  display: flex;

  width: 100%;
  padding: var(--spacing-125) var(--spacing-200);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.deposit-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  margin-right: var(--spacing-150);

  color: var(--icon-brand);

  background-color: var(--background-base);
  border-radius: var(--border-radius-full);
}

.deposit-header {
  display: flex;
  gap: var(--spacing-050);
  align-items: flex-end;
  margin-bottom: var(--spacing-025);
}

.deposit-title {
  font: var(--mobile-text-medium);
}

.deposit-badge {
  padding: var(--spacing-025) var(--spacing-075);

  font: var(--mobile-caption-2-medium);
  color: var(--icon-brand);

  background-color: rgb(254 94 1 / 16%);
  border-radius: var(--border-radius-200);
}

.deposit-date {
  font: var(--mobile-caption-1-regular);
  color: var(--text-tertiary);
  text-align: left;
}

.deposit-account {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  margin-left: auto;
}

.account-currency {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  span {
    font: var(--mobile-text-medium);
    color: var(--text-tertiary);
  }
}

.account-fiat {
  align-self: flex-end;
  font: var(--mobile-caption-1-regular);
  color: var(--text-tertiary);
}

.header-icon {
  color: var(--icon-tertiary);
}

.verification-kyc-modal.mobile {
  position: relative;
  width: auto;
  padding: var(--spacing-450) var(--spacing-200) 0;
  text-align: center;

  .header-section {
    gap: var(--spacing-200);
  }

  .close-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    display: flex;
    justify-content: flex-end;

    width: 100%;
    padding: var(--spacing-050);

    .close-icon {
      padding: var(--spacing-125);
    }
  }

  .content {
    gap: var(--spacing-300);
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }

  .title-text {
    font: var(--mobile-title-1-semibold);
  }

  .text-section {
    gap: var(--spacing-050);
  }

  .button-wrapper {
    width: 100%;
    padding: var(--spacing-050) 0 var(--spacing-200);

    .submit-button {
      width: 100%;
    }
  }
}
</style>
